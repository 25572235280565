import React, { useState } from 'react';
import * as R from 'ramda';

import CreateWorkForAssetModal from '@atom/components/common/createWorkModals/CreateWorkForAssetModal';
import HistoryDrawer from '@atom/components/common/history/HistoryDrawer';
import MoveInventoryModal from '@atom/components/common/MoveInventoryModal';
import RenameModal from '@atom/components/common/RenameModal';
// @ts-ignore
import folderMoveIcon from '@atom/components/common/svgIcons/folderMoveWhite.svg';
import NewWorkOrderIcon from '@atom/components/common/svgIcons/NewWorkOrderIcon';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Button, Icon, IconButton, Modal, Progress, Snackbar } from '@atom/mui';
import colors from '@atom/styles/colors';
import { InventoryAssetDetailType } from '@atom/types/inventory';
import { PolicyAction } from '@atom/types/policy';
import { hasAccess } from '@atom/utilities/accessUtilities';
import api from '@atom/utilities/api';
import { hasRolePermissions, ROLE_SETS } from '@atom/utilities/authUtilities';
import { INVENTORY_ASSETS_ENDPOINT } from '@atom/utilities/endpoints';
// TODO: [AM-15829]: Release cost based as work root feature
import {
  Environment,
  isCurrentEnvironment,
} from '@atom/utilities/featureToggleUtilities';
import history from '@atom/utilities/history';

import AssetDetailPagination from './AssetDetailPagination';
import AssetOptionMenu from './AssetOptionMenu';
import EditAssetModal from './EditAssetModal';

import './header.css';

interface PassedProps {
  inventoryAssetDetail: InventoryAssetDetailType;
  loadingApproveAll: boolean;
  onAssetEdit: (
    id: string,
    name: string,
    rate?: number,
    unit?: string,
    isStockBased?: boolean,
    isStartEndReading?: boolean,
    isStartEndCalculated?: boolean,
    quantityRemaining?: number,
  ) => void;
  onApproveAllPendingChanges: () => void;
  inventoryMoveDisabled: boolean;
}

type Props = PassedProps;

const HeaderControls = ({
  inventoryAssetDetail,
  onApproveAllPendingChanges,
  loadingApproveAll,
  onAssetEdit,
  inventoryMoveDisabled,
}: Props) => {
  const preferences = usePreferences();
  const requireWorkType = R.pathOr(
    false,
    ['workOrders', 'requireWorkType'],
    preferences,
  );

  const [renameModalOpen, setRenameModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [workModalOpen, setWorkModalOpen] = useState<boolean>(false);
  const [approveAllOpen, setApproveAllOpen] = useState<boolean>(false);
  const [moveFolderModalOpen, setMoveFolderModalOpen] = useState<boolean>(
    false,
  );
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const approveAllComponent = loadingApproveAll ? (
    <Progress progressColor={colors.neutral.white} size={25} thickness={2} />
  ) : (
    <IconButton
      edge="end"
      tooltip="Approve All Changes"
      onClick={() => setApproveAllOpen(true)}
    >
      <Icon color={colors.neutral.white}>done_all</Icon>
    </IconButton>
  );

  const handleApproveAll = () => {
    onApproveAllPendingChanges();
    setApproveAllOpen(false);
  };

  const handleDelete = async () => {
    const endpoint = `${INVENTORY_ASSETS_ENDPOINT}/${inventoryAssetDetail.id}`;
    try {
      await api.delete(endpoint);
      history.push('/inventory');
    } catch (error) {
      if (error.response && error.response.status === 422) {
        Snackbar.error({
          message:
            'Work is currently associated with this inventory asset. Deletion is not allowed.',
        });
      }
    }
  };

  const canRename = hasAccess(
    PolicyAction.RENAME,
    inventoryAssetDetail.actions,
  );
  const canUpdate = hasAccess(
    PolicyAction.UPDATE,
    inventoryAssetDetail.actions,
  );
  const canManageChanges = hasAccess(
    PolicyAction.MANAGE_INVENTORY_CHANGES,
    inventoryAssetDetail.actions,
  );

  const showApproveAll = canManageChanges && !!inventoryAssetDetail.changeType;
  const showEdit = inventoryAssetDetail.isMaterial && (canRename || canUpdate);
  const showRename = !inventoryAssetDetail.isMaterial && canRename;
  const showMoveInventory =
    hasRolePermissions(ROLE_SETS.MANAGER) && !inventoryMoveDisabled;
  const showCreateWork = isCurrentEnvironment([Environment.DEV, Environment.QA])
    ? hasRolePermissions(ROLE_SETS.INSPECTOR)
    : hasRolePermissions(ROLE_SETS.INSPECTOR) &&
      !inventoryAssetDetail.isMaterial;

  const showOptionMenu = hasRolePermissions(ROLE_SETS.MANAGER);
  const canDelete = hasRolePermissions(ROLE_SETS.ORG_ADMIN);

  const getDeleteFooter = () => (
    <div>
      <Button onClick={() => setOpenDeleteModal(false)}>CANCEL</Button>
      <Button color="error" variant="contained" onClick={handleDelete}>
        DELETE
      </Button>
    </div>
  );

  return (
    <>
      <div styleName="icon-bar">
        {inventoryAssetDetail.cursor && (
          <AssetDetailPagination asset={inventoryAssetDetail} />
        )}
        {showApproveAll && approveAllComponent}
        {showEdit && (
          <IconButton
            edge="end"
            tooltip="Edit"
            onClick={() => setEditModalOpen(true)}
          >
            <Icon color={colors.neutral.white}>edit</Icon>
          </IconButton>
        )}
        {showRename && (
          <IconButton
            edge="end"
            tooltip="Rename"
            onClick={() => setRenameModalOpen(true)}
          >
            <Icon color={colors.neutral.white}>edit</Icon>
          </IconButton>
        )}
        <HistoryDrawer subject="assets" id={inventoryAssetDetail.id} />
        {showMoveInventory && (
          <IconButton
            edge="end"
            tooltip="Move to Folder"
            onClick={() => setMoveFolderModalOpen(true)}
          >
            <img src={folderMoveIcon} />
          </IconButton>
        )}
        {showCreateWork && (
          <IconButton
            edge="end"
            tooltip="Create Work"
            onClick={() => setWorkModalOpen(true)}
          >
            <NewWorkOrderIcon />
          </IconButton>
        )}
        {showOptionMenu && (
          <AssetOptionMenu
            asset={inventoryAssetDetail}
            setOpenDeleteModal={setOpenDeleteModal}
            canDelete={canDelete}
          />
        )}
      </div>
      <Modal
        open={approveAllOpen}
        title="Approve All Changes"
        loading={loadingApproveAll}
        onConfirm={handleApproveAll}
        onCancel={() => setApproveAllOpen(false)}
        confirmButtonText="Approve All"
      >
        <span>
          Are you sure you want to approve all changes to{' '}
          <strong>{inventoryAssetDetail.name}</strong>?
        </span>
      </Modal>
      <RenameModal
        id={inventoryAssetDetail.id}
        name={inventoryAssetDetail.name}
        type="inventory"
        renameAction={onAssetEdit}
        open={renameModalOpen}
        closeModal={() => setRenameModalOpen(false)}
      />
      <EditAssetModal
        inventoryAssetDetail={inventoryAssetDetail}
        onAssetEdit={onAssetEdit}
        open={editModalOpen}
        closeModal={() => setEditModalOpen(false)}
      />
      <MoveInventoryModal
        id={inventoryAssetDetail.id}
        open={moveFolderModalOpen}
        closeModal={() => setMoveFolderModalOpen(false)}
      />
      <CreateWorkForAssetModal
        redirectOnCreate
        open={workModalOpen}
        onClose={() => setWorkModalOpen(false)}
        asset={inventoryAssetDetail}
        workTemplateRequired={requireWorkType}
      />
      <Modal
        title="Delete"
        open={openDeleteModal}
        onCancel={() => setOpenDeleteModal(false)}
        onExited={() => setOpenDeleteModal(false)}
        data-cy="inventoryDetailDeleteModal"
        width="sm"
        footer={getDeleteFooter()}
      >
        Are you sure you want to delete {inventoryAssetDetail.name}?
      </Modal>
    </>
  );
};

export default HeaderControls;
