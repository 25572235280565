/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import NewWorkOrderIcon from '@atom/components/common/svgIcons/NewWorkOrderIcon';
import { WORK_ORDER_BULK_CREATE, WORK_ORDER_CREATE } from '@atom/graph/work';
import { useCurrentLocation } from '@atom/hooks/useCurrentLocation';
import {
  Button,
  Icon,
  IconButton,
  Modal,
  RadioGroup,
  Snackbar,
} from '@atom/mui';
import colors from '@atom/styles/colors';
import { InventoryAssetDetailType } from '@atom/types/inventory';
import { PolicyAction } from '@atom/types/policy';
import {
  WorkOrderBulkCounts,
  WorkOrderBulkCreateInput,
  WorkOrderCreateInput,
  WorkOrderDetailType,
  WorkOrderType,
} from '@atom/types/work';
import {
  SearchWorkTemplate,
  WorkTemplateListItem,
} from '@atom/types/workTemplate';
// TODO: [AM-15829]: Release cost based as work root feature
import {
  Environment,
  isCurrentEnvironment,
} from '@atom/utilities/featureToggleUtilities';
import history from '@atom/utilities/history';
import {
  convertDateToMillisGMTMidday,
  generateYYYYMM,
  generateYYYYMMName,
} from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import InventoryAssetTree from '../inventoryAssetTree/InventoryAssetTree';

import CreateBulkWorkByQuantity from './CreateBulkWorkByQuantity';
import CreateSingleWork from './CreateSingleWork';
import CreateWorkTemplateSelection from './CreateWorkTemplateSelection';

import './createWorkModals.css';

const { RadioButton } = RadioGroup;

type WorkTemplate = WorkTemplateListItem | SearchWorkTemplate;

interface Props {
  open: boolean;
  onClose: () => void;
  type?: 'SINGLE' | 'BULK';
  workTemplateRequired?: boolean;
  onWorkOrderCreate?: (workOrder: WorkOrderDetailType) => void;
  redirectOnCreate?: boolean;
}

enum Type {
  SINGLE = 'SINGLE',
  BULK = 'BULK',
}

enum View {
  WORK_TEMPLATE,
  SINGLE,
  BULK,
  INVENTORY,
  BULK_CREATE_CONFIRMATION,
}

const styles = {
  modal: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
  },
  back: {
    marginRight: '1rem',
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1.25rem',
  },
  button: {
    color: colors.brand.blue,
    marginBottom: '1.25rem',
  },
  icon: {
    marginRight: '0.25rem',
  },
};

const modalStyles: { [key in View]: React.CSSProperties } = {
  [View.WORK_TEMPLATE]: {
    ...styles.modal,
    height: '50vh',
    padding: '2rem 3.875rem',
  },
  [View.SINGLE]: styles.modal,
  [View.BULK]: styles.modal,
  [View.INVENTORY]: { ...styles.modal, height: '50vh' },
  [View.BULK_CREATE_CONFIRMATION]: styles.modal,
};

const CreateWorkModal = (props: Props) => {
  const { pointLocation } = useCurrentLocation();

  const {
    open,
    onClose,
    workTemplateRequired,
    onWorkOrderCreate,
    redirectOnCreate,
  } = props;

  const [type, setType] = useState<Type>(
    props.type ? Type[props.type] : Type.SINGLE,
  );
  const [view, setView] = useState<View>(View.WORK_TEMPLATE);
  const [workTemplate, setWorkTemplate] = useState<WorkTemplate>(null);
  const [name, setName] = useState<string>('');
  const [dueDate, setDueDate] = useState<Date>(new Date());
  const [quantity, setQuantity] = useState<number>(1);
  const [asset, setAsset] = useState<InventoryAssetDetailType>(null);
  const [isLocationBased, setIsLocationBased] = useState<boolean>(false);

  const isSingle = type === Type.SINGLE;

  const [createWork, { loading: loadingCreate }] = useMutation<
    { workOrderCreate: WorkOrderDetailType },
    { input: WorkOrderCreateInput }
  >(WORK_ORDER_CREATE);

  const [bulkCreateWork, { loading: loadingBulkCreate }] = useMutation<
    {
      workOrderBulkCreate: WorkOrderBulkCounts;
    },
    { input: WorkOrderBulkCreateInput }
  >(WORK_ORDER_BULK_CREATE);

  const resetState = (type?: Type) => {
    setType(type ? type : props.type ? Type[props.type] : Type.SINGLE);
    setView(View.WORK_TEMPLATE);
    setWorkTemplate(null);
    setName('');
    setDueDate(new Date());
    setQuantity(1);
    setAsset(null);
    setIsLocationBased(false);
  };

  const handleBackClick = () => {
    resetState(type);
  };

  const handleWorkTemplateClick = (template: WorkTemplate) => {
    setName(generateYYYYMMName(template));
    setWorkTemplate({
      ...template,
      type: R.pathOr(WorkOrderType.DEFAULT, ['type'], template),
    });
    setView(isSingle ? View.SINGLE : View.BULK);
  };

  const handleCreateWorkClick = () => {
    setName(generateYYYYMM());
    setView(View.SINGLE);
  };

  const isValid = () => {
    if (isSingle) {
      return (
        name &&
        (!workTemplate?.schemaId || workTemplate?.schemaId === asset?.schemaId)
      );
    }

    return quantity && quantity > 0 && quantity <= 100;
  };

  const createSingleWorkOrder = async () => {
    try {
      // if no asset but work is location based user current location
      const location = !asset && isLocationBased ? pointLocation : null;

      const res = await createWork({
        variables: {
          // @ts-ignore
          input: R.reject(isNilOrEmpty, {
            name,
            location,
            dueDate: convertDateToMillisGMTMidday(dueDate),
            workTemplateId: workTemplate?.id,
            inventoryAssetId: asset?.id,
          }),
        },
      });

      const workOrderData = res?.data?.workOrderCreate;

      if (redirectOnCreate) {
        history.push(`/workOrders/${workOrderData.id}`);
      } else {
        Snackbar.info({
          message: `Created work ${workOrderData.name}.`,
          action: 'View',
          onActionClick: () => history.push(`/workOrders/${workOrderData.id}`),
        });
      }

      if (onWorkOrderCreate) {
        onWorkOrderCreate(workOrderData);
      }

      onClose();
    } catch (error) {
      Snackbar.error({
        message:
          'Something went wrong. Please try again or contact administrator.',
      });
    }
  };

  const createBulkWorkOrders = async () => {
    const location = isLocationBased ? pointLocation : null;

    try {
      const res = await bulkCreateWork({
        variables: {
          // @ts-ignore
          input: R.reject(isNilOrEmpty, {
            quantity,
            location,
            workTemplateId: workTemplate?.id,
            dueDate: convertDateToMillisGMTMidday(dueDate),
          }),
        },
      });

      const totalWorkOrders = res?.data?.workOrderBulkCreate?.totalWorkOrders;

      Snackbar.info({
        message: `Created ${totalWorkOrders} work ${
          totalWorkOrders > 1 ? 'orders' : 'order'
        }.`,
        action: 'View',
        onActionClick: () => history.push('/workOrders'),
      });

      onClose();
    } catch (error) {
      Snackbar.error({
        message:
          'Something went wrong. Please try again or contact administrator.',
      });
    }
  };

  const handleSubmit = () => {
    if (isSingle) {
      createSingleWorkOrder();
    } else {
      createBulkWorkOrders();
    }
  };

  const getContent = () => {
    const createWorkTemplateSelectionLabel = workTemplateRequired
      ? null
      : isSingle
      ? 'Or start with a work template:'
      : 'Start with a work template that is not linked to an asset type:';

    const requireAsset = isSingle && !!workTemplate?.schemaId;

    switch (view) {
      case View.WORK_TEMPLATE:
        return (
          <CreateWorkTemplateSelection
            label={createWorkTemplateSelectionLabel}
            emptyContentLabel={
              isSingle ? (
                'There are 0 work templates.'
              ) : (
                <>
                  <div>
                    There are 0 work templates for multiple work creation.
                  </div>
                  <div>
                    Please create one if you want to create multiple work.
                  </div>
                </>
              )
            }
            onClick={template => handleWorkTemplateClick(template)}
            hasSchema={!isSingle ? false : null}
            action={PolicyAction.CREATE}
          />
        );
      case View.SINGLE:
        return (
          <CreateSingleWork
            name={name}
            onNameChange={name => setName(name)}
            dueDate={dueDate}
            onDueDateChange={setDueDate}
            asset={asset}
            onAssetChange={setAsset}
            onAssetViewClick={() => setView(View.INVENTORY)}
            isLocationBased={isLocationBased}
            onLocationBasedChange={setIsLocationBased}
            workTemplate={workTemplate}
            requireAsset={requireAsset}
          />
        );
      case View.BULK:
        return (
          <CreateBulkWorkByQuantity
            dueDate={dueDate}
            onDueDateChange={setDueDate}
            quantity={quantity}
            onQuantityChange={setQuantity}
            isLocationBased={isLocationBased}
            onLocationBasedChange={setIsLocationBased}
            workTemplate={workTemplate}
          />
        );
      case View.INVENTORY:
        return (
          <InventoryAssetTree
            search="assets"
            {...(isCurrentEnvironment([Environment.DEV, Environment.QA])
              ? {}
              : { includeMaterials: false })}
            schemaId={workTemplate?.schemaId}
            onAssetClick={asset => {
              setAsset(asset);
              setView(View.SINGLE);
              setIsLocationBased(
                isCurrentEnvironment([Environment.DEV, Environment.QA])
                  ? !asset?.isMaterial
                  : true,
              );
            }}
          />
        );
      case View.BULK_CREATE_CONFIRMATION:
        return <div>This action will create multiple work. Are you sure?</div>;
      default:
        return null;
    }
  };

  const getTitle = (): React.ReactNode => {
    if (view === View.INVENTORY) {
      return (
        <>
          <IconButton style={styles.back} onClick={() => setView(View.SINGLE)}>
            <Icon>arrow_back</Icon>
          </IconButton>
          Add Asset
        </>
      );
    }

    if (view === View.BULK_CREATE_CONFIRMATION) {
      return 'Create Multiple Work?';
    }

    return 'Create Work';
  };

  const getFooter = (): React.ReactNode => {
    switch (view) {
      case View.WORK_TEMPLATE: {
        return <Button onClick={onClose}>Cancel</Button>;
      }
      case View.SINGLE:
      case View.BULK: {
        return (
          <div styleName="footer">
            <div>
              <Button
                onClick={handleBackClick}
                data-cy="createWorkModalBackButton"
              >
                Back
              </Button>
            </div>
            <div>
              <Button
                onClick={onClose}
                style={{ marginRight: '0.5rem' }}
                data-cy="createWorkModalCancelButton"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={!isValid() || loadingCreate || loadingBulkCreate}
                onClick={() =>
                  quantity > 1
                    ? setView(View.BULK_CREATE_CONFIRMATION)
                    : handleSubmit()
                }
                data-cy="createWorkModalConfirmationCreateButton"
              >
                Create
              </Button>
            </div>
          </div>
        );
      }
      case View.BULK_CREATE_CONFIRMATION: {
        return (
          <div>
            <Button onClick={onClose} style={{ marginRight: '0.5rem' }}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={loadingBulkCreate}
              onClick={handleSubmit}
              data-cy="createWorkModalBulkConfirmationCreateButton"
            >
              Create
            </Button>
          </div>
        );
      }
      default: {
        return <div />;
      }
    }
  };

  return (
    <Modal
      title={getTitle()}
      open={open}
      onCancel={onClose}
      onExited={() => resetState()}
      footer={getFooter()}
      contentStyle={modalStyles[view] || styles.modal}
      data-cy="createWorkModal"
    >
      {view === View.WORK_TEMPLATE && (
        <>
          {!props.type && (
            <RadioGroup
              style={styles.radioGroup}
              value={type}
              onChange={event => setType(event.target.value)}
            >
              <RadioButton value={Type.SINGLE} label="Single" />
              <RadioButton value={Type.BULK} label="Multiple" />
            </RadioGroup>
          )}
          {type === Type.SINGLE && !workTemplateRequired && (
            <div>
              <Button
                variant="outlined"
                style={styles.button}
                onClick={handleCreateWorkClick}
                data-cy="createWorkModalCreateButton"
              >
                <Icon style={styles.icon}>
                  <NewWorkOrderIcon color={colors.brand.blue} />
                </Icon>
                Create New Work
              </Button>
            </div>
          )}
        </>
      )}
      {getContent()}
    </Modal>
  );
};

export default CreateWorkModal;
