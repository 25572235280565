import React, { useContext, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import { UPDATE_BUDGET_UNITS } from '@atom/graph/budget';
import { usePreferences } from '@atom/hooks/usePreferences';
import {
  Button,
  Checkbox,
  Icon,
  IconButton,
  ListTable,
  Modal,
  Tooltip,
} from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import {
  BudgetStatus,
  BudgetUnit,
  BudgetUnitUpdateInput,
} from '@atom/types/budget';
import { PolicyAction } from '@atom/types/policy';
import { hasAccess } from '@atom/utilities/accessUtilities';
import { pluralize } from '@atom/utilities/stringUtilities';

import BudgetDetailContext from '../BudgetDetailContext';
import {
  BUDGET_COL_WIDTH,
  BudgetStatusModals,
  displayError,
  ICON_COL_WIDTH,
  isStatusLocked,
  SELECT_COL_WIDTH,
} from '../budgetDetailUtils';

import BudgetDetailChildUnitRow from './BudgetDetailChildUnitRow';

import '../budgetDetail.css';

const { TableHead, TableRow, TableCell, TableBody } = ListTable;

const styles = {
  itemCount: {
    color: colors.neutral.gray,
    paddingLeft: '0.25rem',
    fontSize: fonts.lg,
    fontWeight: '500',
  },
  controlsDivider: {
    borderRight: `1px solid ${colors.neutral.gray}`,
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    margin: '0.75rem 0',
    height: '2rem',
  },
  headingText: {
    fontSize: fonts.lg,
    fontWeight: '500',
  },
  headerItem: {
    margin: '0 0.5rem',
    padding: '0',
    minWidth: '0',
  },
};

const BudgetDetailChildUnits = () => {
  const {
    budget,
    childBudgetUnits,
    showTracking,
    selectedUnits,
    setSelectedUnits,
    parentBudgetUnit,
    getParentUnit,
    categoryIds,
    budgetItemTemplateNames,
  } = useContext(BudgetDetailContext);

  const preferences = usePreferences();
  const showApprovalFlow: boolean = R.pathOr(
    false,
    ['budgeting', 'showApprovalFlow'],
    preferences,
  );
  const statusLocked = isStatusLocked(budget?.id);

  const [openModal, setOpenModal] = useState<BudgetStatusModals>();

  const selectAllChecked = useMemo(
    () =>
      selectedUnits.size === childBudgetUnits?.length && selectedUnits.size > 0,
    [selectedUnits, childBudgetUnits],
  );

  const handleCheckAll = (): void => {
    if (selectAllChecked) {
      setSelectedUnits(new Set([]));
    } else {
      setSelectedUnits(new Set(childBudgetUnits.map(({ id }) => id)));
    }
  };

  const selectedUnitsHydrated: BudgetUnit[] = useMemo(() => {
    return Array.from(selectedUnits).map(id =>
      childBudgetUnits.find(unit => id === unit.id),
    );
  }, [childBudgetUnits, selectedUnits]);

  const selectedStatuses: BudgetStatus[] = useMemo(() => {
    return selectedUnitsHydrated.map(({ status }) => status);
  }, [selectedUnitsHydrated]);

  // Checks all selected units for ability to perform
  //   action based on selected unit status and RBAC
  const canApprove = useMemo(() => {
    return (
      selectedUnitsHydrated.every(unit =>
        hasAccess(PolicyAction.APPROVE, unit.actionsOnApprovalProcess),
      ) && selectedStatuses.every(status => status === BudgetStatus.SUBMITTED)
    );
  }, [selectedUnitsHydrated, selectedStatuses]);

  const canRevertToSubmitted = useMemo(() => {
    return (
      selectedUnitsHydrated.every(unit =>
        hasAccess(
          PolicyAction.REVERT_TO_SUBMITTED_FROM_APPROVED,
          unit.actionsOnApprovalProcess,
        ),
      ) && selectedStatuses.every(status => status === BudgetStatus.APPROVED)
    );
  }, [selectedUnitsHydrated, selectedStatuses]);

  const canRevertToDraft = useMemo(() => {
    return (
      selectedUnitsHydrated.every(
        unit =>
          (hasAccess(
            PolicyAction.REVERT_TO_DRAFT_FROM_APPROVED,
            unit.actionsOnApprovalProcess,
          ) &&
            unit.status === BudgetStatus.APPROVED) ||
          (hasAccess(
            PolicyAction.REVERT_TO_DRAFT_FROM_SUBMITTED,
            unit.actionsOnApprovalProcess,
          ) &&
            unit.status === BudgetStatus.SUBMITTED),
      ) && selectedStatuses.every(status => status !== BudgetStatus.DRAFT)
    );
  }, [selectedUnitsHydrated, selectedStatuses]);

  const [updateBudgetStatus] = useMutation<{
    input: BudgetUnitUpdateInput;
  }>(UPDATE_BUDGET_UNITS);

  const refreshUnit = () => {
    getParentUnit({
      variables: {
        input: {
          budgetId: budget?.id,
          budgetUnitId: parentBudgetUnit.id,
          categoryIds,
          budgetItemTemplateNames,
        },
      },
    });
  };

  const getSelectedIdsArray = (): string[] =>
    Array.from(selectedUnits).map(id => id);

  const removeDraftIds = (ids: string[]): string[] =>
    ids.filter((_, idx) => {
      return selectedStatuses[idx] !== BudgetStatus.DRAFT;
    });

  const affectedBulkCount = () =>
    removeDraftIds(getSelectedIdsArray())?.length || 0;

  const sendUpdate = (status: BudgetStatus, action: PolicyAction) => {
    // filter out draft ids for APPROVE or REVERT_TO_SUBMITTED updates
    const budgetUnitIds =
      status === BudgetStatus.DRAFT
        ? getSelectedIdsArray()
        : removeDraftIds(getSelectedIdsArray());

    return updateBudgetStatus({
      variables: {
        input: {
          budgetId: budget?.id,
          budgetUnitIds,
          status,
          action,
        },
      },
    });
  };

  const handleBulkApprove = async () => {
    try {
      await sendUpdate(BudgetStatus.APPROVED, PolicyAction.APPROVE);
      refreshUnit();
    } catch (error) {
      displayError();
    } finally {
      setOpenModal(null);
    }
  };

  const handleBulkRevertToSubmitted = async () => {
    try {
      await sendUpdate(
        BudgetStatus.SUBMITTED,
        PolicyAction.REVERT_TO_SUBMITTED_FROM_APPROVED,
      );
      refreshUnit();
    } catch (error) {
      displayError();
    } finally {
      setOpenModal(null);
    }
  };

  const handleBulkRevertToDraft = async () => {
    try {
      const action = selectedStatuses.some(
        status => status === BudgetStatus.APPROVED,
      )
        ? PolicyAction.REVERT_TO_DRAFT_FROM_APPROVED
        : PolicyAction.REVERT_TO_DRAFT_FROM_SUBMITTED;
      await sendUpdate(BudgetStatus.DRAFT, action);
      refreshUnit();
    } catch (error) {
      displayError();
    } finally {
      setOpenModal(null);
    }
  };

  return (
    <div>
      <div style={styles.heading}>
        {selectedUnits.size > 0 ? (
          <>
            <IconButton
              onClick={() => setSelectedUnits(new Set([]))}
              style={styles.headerItem}
            >
              <Icon>close</Icon>
            </IconButton>
            <span>{selectedUnits.size} selected</span>
            <Tooltip
              title={
                !canApprove
                  ? 'Select only Submitted budgets to bulk approve'
                  : ''
              }
              placement="top"
              children={
                <div>
                  <Button
                    onClick={() =>
                      setOpenModal(BudgetStatusModals.APPROVE_BUDGET)
                    }
                    color="primary"
                    style={styles.headerItem}
                    disabled={!canApprove}
                  >
                    APPROVE
                  </Button>
                </div>
              }
            />
            <Tooltip
              title={
                !canRevertToSubmitted
                  ? 'Select only Approved budgets to revert'
                  : ''
              }
              placement="top"
              children={
                <div>
                  <Button
                    onClick={() =>
                      setOpenModal(
                        BudgetStatusModals.REVERT_BUDGET_TO_SUBMITTED,
                      )
                    }
                    style={styles.headerItem}
                    disabled={!canRevertToSubmitted}
                  >
                    REVERT TO SUBMITTED
                  </Button>
                </div>
              }
            />
            <Tooltip
              title={
                !canRevertToDraft
                  ? 'Select only Approved and Submitted budgets to revert'
                  : ''
              }
              placement="top"
              children={
                <div>
                  <Button
                    onClick={() =>
                      setOpenModal(BudgetStatusModals.REVERT_BUDGET_TO_DRAFT)
                    }
                    style={styles.headerItem}
                    disabled={!canRevertToDraft}
                  >
                    REVERT TO DRAFT
                  </Button>
                </div>
              }
            />
          </>
        ) : (
          <>
            <span style={styles.headingText}>Budget Units</span>
            <span style={styles.itemCount}>
              {childBudgetUnits?.length || 0}
            </span>
          </>
        )}
      </div>
      <ListTable fullHeight={false}>
        <TableHead>
          <TableRow>
            {showApprovalFlow && !statusLocked && (
              <TableCell width={SELECT_COL_WIDTH} variant="head">
                <Checkbox checked={selectAllChecked} onClick={handleCheckAll} />
              </TableCell>
            )}
            <TableCell variant="head">NAME</TableCell>
            {showApprovalFlow && (
              <TableCell width={BUDGET_COL_WIDTH} variant="head">
                STATUS
              </TableCell>
            )}
            <TableCell width={BUDGET_COL_WIDTH} align="right" variant="head">
              BUDGET
            </TableCell>
            {showTracking && (
              <>
                <TableCell
                  width={BUDGET_COL_WIDTH}
                  align="right"
                  variant="head"
                >
                  ACTUAL
                </TableCell>
                <TableCell
                  width={BUDGET_COL_WIDTH}
                  align="right"
                  variant="head"
                >
                  FUTURE FIXED
                </TableCell>
                <TableCell
                  width={BUDGET_COL_WIDTH}
                  align="right"
                  variant="head"
                >
                  REMAINING
                </TableCell>
              </>
            )}
            <TableCell width={ICON_COL_WIDTH} variant="head" />
          </TableRow>
        </TableHead>
        <TableBody>
          {childBudgetUnits.map(budgetUnit => (
            <BudgetDetailChildUnitRow
              key={budgetUnit.id}
              budgetUnit={budgetUnit}
            />
          ))}
        </TableBody>
      </ListTable>
      <Modal
        open={openModal === BudgetStatusModals.APPROVE_BUDGET}
        onCancel={() => setOpenModal(null)}
        onConfirm={() => handleBulkApprove()}
        title="Approve Budget?"
        disabled={affectedBulkCount() === 0}
        confirmButtonText="Approve"
      >
        <p>
          Are you sure you want to approve the budget for the
          <span> {affectedBulkCount()} </span> selected
          <span> {pluralize(affectedBulkCount(), 'unit')} </span> ?
        </p>
      </Modal>
      <Modal
        open={openModal === BudgetStatusModals.REVERT_BUDGET_TO_DRAFT}
        onCancel={() => setOpenModal(null)}
        onConfirm={() => handleBulkRevertToDraft()}
        title="Revert Budget to Draft?"
        disabled={affectedBulkCount() === 0}
        confirmButtonText="Revert to Draft"
      >
        <>
          <p>
            Reverting to Draft will reset the approval process for this budget
            unit, allowing users to make edits and resubmit. Are you sure you
            want to revert the budget for<span> {affectedBulkCount()} </span>
            selected <span> {pluralize(affectedBulkCount(), 'unit')} </span> to
            Draft?
          </p>
          <p>
            Please note: Reverting the status of lower-level units will also
            impact their higher-level units, if applicable.
          </p>
        </>
      </Modal>
      <Modal
        open={openModal === BudgetStatusModals.REVERT_BUDGET_TO_SUBMITTED}
        onCancel={() => setOpenModal(null)}
        onConfirm={() => handleBulkRevertToSubmitted()}
        title="Revert Budget to Submitted?"
        disabled={affectedBulkCount() === 0}
        confirmButtonText="Revert to Submitted"
      >
        <>
          <p>
            Are you sure you want to revert the budget for
            <span> {affectedBulkCount()} </span> selected
            <span> {pluralize(affectedBulkCount(), 'unit')} </span> to
            Submitted?
          </p>
          <p>
            Please note: Reverting the status of lower-level units will also
            impact their higher-level units, if applicable.
          </p>
        </>
      </Modal>
    </div>
  );
};

export default BudgetDetailChildUnits;
