import React, { Dispatch, SetStateAction } from 'react';

import {
  BasicBudget,
  BasicBudgetUnit,
  Budget,
  BudgetCategory,
  BudgetItem,
  BudgetItemTemplate,
  BudgetSummary,
  BudgetUnit,
} from '@atom/types/budget';

import { EditField } from './budgetDetailUtils';

interface Context {
  budget: Budget;
  parentBudgetUnit: BudgetUnit;
  setParentBudgetUnit: Dispatch<SetStateAction<BudgetUnit>>;
  childBudgetUnits: BudgetUnit[];
  setChildBudgetUnits: Dispatch<SetStateAction<BudgetUnit[]>>;
  budgetCategories: BudgetCategory[];
  setBudgetCategories: Dispatch<SetStateAction<BudgetCategory[]>>;
  unitsVisited: { id?: BasicBudgetUnit };
  setUnitsVisited: Dispatch<SetStateAction<{ id?: BasicBudgetUnit }>>;
  categoryFilters: BudgetCategory[];
  setCategoryFilters: Dispatch<SetStateAction<BudgetCategory[]>>;
  categoryIds: string[];
  budgetItemTemplateFilters: BudgetItemTemplate[];
  setBudgetItemTemplateFilters: Dispatch<SetStateAction<BudgetItemTemplate[]>>;
  budgetItemTemplateNames: string[];
  editingItem: BudgetItem;
  setEditingItem: Dispatch<SetStateAction<BudgetItem>>;
  editingField: EditField;
  setEditingField: Dispatch<SetStateAction<EditField>>;
  expandedCategories: Set<string>;
  setExpandedCategories: Dispatch<SetStateAction<Set<string>>>;
  excludeZeroBudgetItems: boolean;
  setExcludeZeroBudgetItems: Dispatch<SetStateAction<boolean>>;
  getParentUnit: (variables: any) => void;
  loadingParentUnit: boolean;
  comparisonBudgets: BasicBudget[];
  setComparisonBudgets: Dispatch<SetStateAction<BasicBudget[]>>;
  budgetSummaries: BudgetSummary[];
  setBudgetSummaries: Dispatch<SetStateAction<BudgetSummary[]>>;
  budgetOverviewSummaries: BudgetSummary[];
  setBudgetOverviewSummaries: Dispatch<SetStateAction<BudgetSummary[]>>;
  openComparisonRowId: string;
  setOpenComparisonRowId: Dispatch<SetStateAction<string>>;
  fetchSummaries: (options: { variables: any }) => void;
  loadingSummaries: boolean;
  itemHoverId: string;
  setItemHoverId: Dispatch<SetStateAction<string>>;
  showTracking: boolean;
  setShowTracking: Dispatch<SetStateAction<boolean>>;
  showExpenditures: boolean;
  setShowChart: Dispatch<SetStateAction<boolean>>;
  showChart: boolean;
  loadingBudgetOptions: boolean;
  totalBudgetOptions: number;
  setTotalBudgetOptions: Dispatch<SetStateAction<number>>;
  budgetOptions: Budget[];
  setBudgetOptions: Dispatch<SetStateAction<Budget[]>>;
  getBudgetOptions: (options: { variables: any }) => void;
  selectedUnits: Set<string>;
  setSelectedUnits: Dispatch<SetStateAction<Set<string>>>;
  childBudgetUnitStatuses: BudgetUnit[];
}

export const initialState: Context = {
  budget: null,
  parentBudgetUnit: null,
  setParentBudgetUnit: () => {},
  childBudgetUnits: null,
  setChildBudgetUnits: () => {},
  budgetCategories: null,
  setBudgetCategories: () => {},
  unitsVisited: {},
  setUnitsVisited: () => {},
  categoryFilters: [],
  setCategoryFilters: () => {},
  categoryIds: [],
  budgetItemTemplateFilters: [],
  setBudgetItemTemplateFilters: () => {},
  budgetItemTemplateNames: [],
  editingItem: null,
  setEditingItem: () => {},
  editingField: null,
  setEditingField: () => {},
  expandedCategories: new Set([]),
  setExpandedCategories: () => {},
  excludeZeroBudgetItems: false,
  setExcludeZeroBudgetItems: () => {},
  getParentUnit: () => {},
  loadingParentUnit: null,
  comparisonBudgets: null,
  setComparisonBudgets: () => {},
  budgetSummaries: null,
  setBudgetSummaries: () => {},
  budgetOverviewSummaries: null,
  setBudgetOverviewSummaries: () => {},
  openComparisonRowId: null,
  setOpenComparisonRowId: () => {},
  fetchSummaries: () => {},
  loadingSummaries: null,
  itemHoverId: null,
  setItemHoverId: () => {},
  showTracking: null,
  setShowTracking: () => {},
  showExpenditures: null,
  setShowChart: () => {},
  showChart: null,
  loadingBudgetOptions: null,
  totalBudgetOptions: null,
  setTotalBudgetOptions: () => {},
  budgetOptions: null,
  setBudgetOptions: () => {},
  getBudgetOptions: () => {},
  selectedUnits: new Set([]),
  setSelectedUnits: () => {},
  childBudgetUnitStatuses: null,
};

const BudgetDetailContext = React.createContext<Context>(initialState);

export default BudgetDetailContext;
