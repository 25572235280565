import React, { useContext, useEffect, useRef, useState } from 'react';
import { DragDrop } from 'react-beautiful-dnd';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import {
  DragDropContext,
  Draggable,
  Droppable,
} from '@atom/components/common/dragAndDrop';
import SchemaDetailContext, {
  DragDropType,
} from '@atom/components/schemaDetail/SchemaDetailContext';
import { ELEMENT_UPDATE } from '@atom/graph/schema';
import { List } from '@atom/mui';
import {
  ElementUpdateInput,
  SchemaTree,
  SchemaTreeAttributeGroup,
} from '@atom/types/schema';
import {
  Environment,
  isCurrentEnvironment,
} from '@atom/utilities/featureToggleUtilities';

import AttributeGroupRow from './AttributeGroupRow';
import DetailHeader from './DetailHeader';
import {
  reorderAttributeGroups,
  reorderAttributes,
} from './subItemDetailUtilities';

import './subItemDetail.css';

const styles = {
  list: {
    padding: 0,
    minHeight: '1rem',
  },
};

const SubItemDetail = () => {
  const container = useRef<HTMLDivElement>(null);

  const {
    schemaTree,
    selectedSubItem,
    refetchSchemaTree,
    setSelectedSubItem,
    selectedAttributeRoute,
    setSelectedAttributeRoute,
  } = useContext(SchemaDetailContext);

  const [createdAttributeGroupId, setCreatedAttributeGroupId] = useState<
    string
  >();

  const [updateElement] = useMutation<
    { elementUpdate: SchemaTree },
    { input: ElementUpdateInput }
  >(ELEMENT_UPDATE);

  useEffect(() => {
    // Gets newly created group and scrolls to it.
    const lastGroupId = R.last(selectedSubItem.attributeGroups)?.id;

    if (lastGroupId === createdAttributeGroupId) {
      container?.current?.scrollIntoView({ behavior: 'smooth' });
      setCreatedAttributeGroupId(null);
    }
  }, [selectedSubItem, createdAttributeGroupId]);

  const onDragEnd = async (result: DragDrop) => {
    const { destination, type } = result;

    if (!destination) {
      return;
    }

    const { updatedAttributeGroups, payload } =
      type === DragDropType.ATTRIBUTE_GROUP
        ? reorderAttributeGroups(selectedSubItem, result)
        : reorderAttributes(selectedSubItem, result);

    setSelectedSubItem({
      ...selectedSubItem,
      attributeGroups: updatedAttributeGroups,
    });

    // If an attribute is currently selected, upgrade its route to account for
    // an attribute being moved from one group to another
    if (selectedAttributeRoute) {
      setSelectedAttributeRoute({
        attributeId: result.draggableId,
        attributeGroupId: result.destination.droppableId,
      });
    }

    await updateElement({
      variables: {
        input: payload,
      },
    });

    refetchSchemaTree();
  };

  const isDisabled = schemaTree?.isPublished;
  // TODO: remove this for expand default GO LIVE
  const isDevOnlyFeature = isCurrentEnvironment([Environment.DEV]);

  const internalContent = selectedSubItem.attributeGroups.map(
    (attributeGroup: SchemaTreeAttributeGroup, index: number) => {
      return (
        <div key={attributeGroup.id} ref={container}>
          <Draggable
            draggableId={attributeGroup.id}
            index={index}
            type={DragDropType.ATTRIBUTE_GROUP}
            isDragDisabled={isDisabled}
          >
            <AttributeGroupRow
              key={attributeGroup.id}
              attributeGroup={attributeGroup}
            />
          </Draggable>
        </div>
      );
    },
  );

  return (
    <>
      <DetailHeader setCreatedAttributeGroupId={setCreatedAttributeGroupId} />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="subItemDetail"
          type={DragDropType.ATTRIBUTE_GROUP}
          isDropDisabled={isDisabled}
        >
          {isDevOnlyFeature ? (
            <List style={styles.list}>{internalContent}</List>
          ) : (
            internalContent
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default SubItemDetail;
