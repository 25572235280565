import React, { ReactNode, useContext, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';

import { UPDATE_BUDGET_UNITS } from '@atom/graph/budget';
import { Button, Menu, Modal, Progress, Tooltip } from '@atom/mui';
import { BudgetStatus, BudgetUnitUpdateInput } from '@atom/types/budget';
import { PolicyAction } from '@atom/types/policy';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailContext from './BudgetDetailContext';
import {
  BudgetStatusModals,
  displayError,
  getStatusLabel,
  isStatusLocked,
} from './budgetDetailUtils';
import { useBudgetApprovalAccess } from './useBudgetApprovalAccess';

import './budgetDetail.css';
const { MenuItem } = Menu;

const DISABLED_SUBMIT_TEXT =
  'All sub budget units must be approved before submitting this budget unit for approval.';

const styles = {
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '1.75rem',
    paddingBottom: '0.75rem',
    marginRight: '0.25rem',
  },
  progress: { paddingRight: '1rem' },
  submitButton: {
    marginRight: '1rem',
  },
  statusLabel: {
    marginRight: '1rem',
  },
};

const BudgetDetailStatus = () => {
  const {
    budget,
    parentBudgetUnit,
    childBudgetUnitStatuses,
    getParentUnit,
    categoryIds,
    budgetItemTemplateNames,
  } = useContext(BudgetDetailContext);
  const [openModal, setOpenModal] = useState<BudgetStatusModals>();
  const statusLocked = isStatusLocked(budget?.id);
  const {
    canAccessApprove,
    canAccessSubmit,
    canAccessRevertToDraftFromApproved,
    canAccessRevertToDraftFromSubmitted,
    canAccessRevertToSubmittedFromApproved,
  } = useBudgetApprovalAccess(parentBudgetUnit?.actionsOnApprovalProcess);

  const parentStatus: BudgetStatus = parentBudgetUnit?.status;

  const canViewOptions: boolean =
    parentStatus === BudgetStatus.SUBMITTED
      ? canAccessRevertToDraftFromSubmitted
      : canAccessRevertToDraftFromApproved ||
        canAccessRevertToSubmittedFromApproved;

  const showAdditionalOptions: boolean =
    !statusLocked && canViewOptions && parentStatus !== BudgetStatus.DRAFT;

  const StatusLabel: ReactNode = useMemo(() => {
    return getStatusLabel(parentStatus);
  }, [parentStatus]);

  const canSubmit = useMemo(() => {
    return parentBudgetUnit?.hasBudgetItems
      ? true
      : childBudgetUnitStatuses?.every(
          ({ status }) => status === BudgetStatus.APPROVED,
        );
  }, [parentBudgetUnit, childBudgetUnitStatuses]);

  const [updateBudgetStatus, { loading: updatingStatus }] = useMutation<{
    input: BudgetUnitUpdateInput;
  }>(UPDATE_BUDGET_UNITS);

  const refreshUnit = () => {
    getParentUnit({
      variables: {
        input: {
          budgetId: budget?.id,
          budgetUnitId: parentBudgetUnit.id,
          categoryIds,
          budgetItemTemplateNames,
        },
      },
    });
  };

  const sendUpdate = (status: BudgetStatus, action: PolicyAction) => {
    return updateBudgetStatus({
      variables: {
        input: {
          budgetId: budget?.id,
          budgetUnitIds: [parentBudgetUnit?.id],
          status,
          action,
        },
      },
    });
  };

  const handleSubmitBudget = async () => {
    try {
      await sendUpdate(BudgetStatus.SUBMITTED, PolicyAction.SUBMIT_DRAFT);
      refreshUnit();
    } catch (error) {
      displayError();
    } finally {
      setOpenModal(null);
    }
  };

  const handleApproveBudget = async () => {
    try {
      await sendUpdate(BudgetStatus.APPROVED, PolicyAction.APPROVE);
      refreshUnit();
    } catch (error) {
      displayError();
    } finally {
      setOpenModal(null);
    }
  };

  const handleRevertToSubmitted = async () => {
    try {
      await sendUpdate(
        BudgetStatus.SUBMITTED,
        PolicyAction.REVERT_TO_SUBMITTED_FROM_APPROVED,
      );
      refreshUnit();
    } catch (error) {
      displayError();
    } finally {
      setOpenModal(null);
    }
  };

  const handleRevertToDraft = async () => {
    try {
      const action =
        parentStatus === BudgetStatus.APPROVED
          ? PolicyAction.REVERT_TO_DRAFT_FROM_APPROVED
          : PolicyAction.REVERT_TO_DRAFT_FROM_SUBMITTED;
      await sendUpdate(BudgetStatus.DRAFT, action);
      refreshUnit();
    } catch (error) {
      displayError();
    } finally {
      setOpenModal(null);
    }
  };

  return (
    <>
      <div style={styles.statusContainer}>
        {isNilOrEmpty(parentBudgetUnit) ? (
          <Progress style={styles.progress} size={20} />
        ) : (
          <>
            <div style={styles.statusLabel}>{StatusLabel}</div>
            {updatingStatus ? (
              <Progress size={20} />
            ) : (
              parentStatus === BudgetStatus.SUBMITTED &&
              canAccessApprove &&
              !statusLocked && (
                <Button
                  style={styles.submitButton}
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    setOpenModal(BudgetStatusModals.APPROVE_BUDGET)
                  }
                  disabled={!canAccessApprove}
                >
                  Approve
                </Button>
              )
            )}
            {!updatingStatus &&
              parentStatus === BudgetStatus.DRAFT &&
              canAccessSubmit &&
              !statusLocked && (
                <Tooltip
                  title={canSubmit ? '' : DISABLED_SUBMIT_TEXT}
                  children={
                    <div>
                      <Button
                        style={styles.submitButton}
                        color="primary"
                        variant="contained"
                        disabled={!canSubmit || !canAccessSubmit}
                        onClick={() =>
                          setOpenModal(BudgetStatusModals.SUBMIT_BUDGET)
                        }
                      >
                        Submit
                      </Button>
                    </div>
                  }
                />
              )}
            {showAdditionalOptions && (
              <Menu>
                {(canAccessRevertToDraftFromApproved ||
                  canAccessRevertToDraftFromSubmitted) &&
                  parentStatus !== BudgetStatus.DRAFT && (
                    <MenuItem
                      onClick={() =>
                        setOpenModal(BudgetStatusModals.REVERT_BUDGET_TO_DRAFT)
                      }
                    >
                      Revert to Draft
                    </MenuItem>
                  )}

                {parentStatus === BudgetStatus.APPROVED &&
                  canAccessRevertToSubmittedFromApproved && (
                    <MenuItem
                      onClick={() =>
                        setOpenModal(
                          BudgetStatusModals.REVERT_BUDGET_TO_SUBMITTED,
                        )
                      }
                    >
                      Revert to Submitted
                    </MenuItem>
                  )}
              </Menu>
            )}
          </>
        )}
      </div>
      <Modal
        open={openModal === BudgetStatusModals.SUBMIT_BUDGET}
        onCancel={() => setOpenModal(null)}
        onConfirm={() => handleSubmitBudget()}
        title="Submit Budget?"
        confirmButtonText="Submit"
      >
        Are you sure you want to submit the budget for "{parentBudgetUnit?.name}
        "?
      </Modal>
      <Modal
        open={openModal === BudgetStatusModals.APPROVE_BUDGET}
        onCancel={() => setOpenModal(null)}
        onConfirm={() => handleApproveBudget()}
        title="Approve Budget?"
        confirmButtonText="Approve"
      >
        Are you sure you want to approve budgets for "{parentBudgetUnit?.name}
        "?
      </Modal>
      <Modal
        open={openModal === BudgetStatusModals.REVERT_BUDGET_TO_DRAFT}
        onCancel={() => setOpenModal(null)}
        onConfirm={() => handleRevertToDraft()}
        title="Revert Budget to Draft?"
        confirmButtonText="Revert to Draft"
      >
        <>
          <p>
            Reverting to Draft will reset the approval process for this budget
            unit, allowing users to make edits and resubmit. Are you sure you
            want to revert the budget for "{parentBudgetUnit?.name}" to Draft?
          </p>
          <p>
            Please note: Reverting the status of lower-level units will also
            impact their higher-level units, if applicable.
          </p>
        </>
      </Modal>
      <Modal
        open={openModal === BudgetStatusModals.REVERT_BUDGET_TO_SUBMITTED}
        onCancel={() => setOpenModal(null)}
        onConfirm={() => handleRevertToSubmitted()}
        title="Revert Budget to Submitted?"
        confirmButtonText="Revert to Submitted"
      >
        <>
          <p>
            Are you sure you want to revert the budget for "
            {parentBudgetUnit?.name}" to Submitted?
          </p>
          <p>
            Please note: Reverting the status of lower-level units will also
            impact their higher-level units, if applicable.
          </p>
        </>
      </Modal>
    </>
  );
};

export default BudgetDetailStatus;
