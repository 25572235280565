import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import * as R from 'ramda';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { GET_BUDGET_CHARTS } from '@atom/graph/budget';
import { LinearProgress } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import {
  BudgetChart,
  BudgetChartsConnection,
  BudgetChartsConnectionInput,
} from '@atom/types/budget';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailContext from '../BudgetDetailContext';

import ExpendituresChartContext from './ExpendituresChartContext';
import ChartLegend from './ExpendituresChartLegend';
import ChartTooltip from './ExpendituresChartTooltip';
import {
  BAR_ANIMATION_MS,
  CHART_HEIGHT,
  CHART_TOP_MARGIN,
  getChartColor,
  GroupKeys,
  LINE_ANIMATION_MS,
  renderLegendText,
  yAxisTickFormatter,
} from './expendituresChartUtils';
import { usePrepExpendituresChartData } from './usePrepExpendituresChartData';

const styles = {
  container: {
    height: `${CHART_HEIGHT}rem`,
  },
  progress: {
    margin: `${CHART_HEIGHT / 2}rem 35%`,
  },
  tooltipContainer: {
    border: `2px solid ${colors.brand.blue}`,
    borderRadius: '0.25rem',
    background: colors.neutral.white,
    padding: '1rem',
  },
  tooltipTitle: {
    fontWeight: 500,
    fontSize: fonts.lg,
  },
  tooltipSubtitle: {
    fontWeight: 500,
    margin: '0.5rem 0',
    fontSize: fonts.md,
  },
  tooltipItem: { display: 'flex', alignItems: 'center', gap: '0.25rem' },
  legendItemsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    justifyContent: 'center',
    marginBottom: '1rem',
  },
  legendLabel: {
    width: '8rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    cursor: 'pointer',
    fontWeight: 500,
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    cursor: 'pointer',
  },
  legendName: {
    maxWidth: '12rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  refreshIcon: {
    position: 'absolute',
    top: '1rem',
    right: '0',
    cursor: 'pointer',
  },
};

const ExpendituresChart = () => {
  const {
    budget,
    parentBudgetUnit,
    categoryIds,
    budgetItemTemplateNames,
    comparisonBudgets,
  } = useContext(BudgetDetailContext);

  const [budgetsRaw, setBudgetsRaw] = useState<BudgetChart[]>([]);
  const [chartData, setChartData] = useState([]);

  const {
    dataFields,
    actualFields,
    remainingFields,
    maxVal,
    data,
  } = usePrepExpendituresChartData(budgetsRaw);

  useEffect(() => setChartData(data), [data]);

  const [fetchCharts, { loading }] = useLazyQuery<
    { budgetCharts: BudgetChartsConnection },
    { input: BudgetChartsConnectionInput }
  >(GET_BUDGET_CHARTS, {
    fetchPolicy: 'no-cache',
    onCompleted: dataResponse => {
      const budgetData: BudgetChart[] = R.pathOr(
        [],
        ['budgetCharts', 'budgetCharts'],
        dataResponse,
      );
      setBudgetsRaw(budgetData);
    },
    onError: () => setBudgetsRaw([]),
  });

  useEffect(() => {
    if (budget && parentBudgetUnit) {
      fetchCharts({
        variables: {
          input: {
            budgetIds: [budget?.id, ...comparisonBudgets.map(({ id }) => id)],
            budgetUnitId: parentBudgetUnit?.id,
            categoryIds,
            itemNames: budgetItemTemplateNames,
          },
        },
      });
    }
  }, [parentBudgetUnit, comparisonBudgets]);

  const getOpacities = useCallback(
    value =>
      dataFields.reduce((acc, field) => ({ ...acc, [field]: value }), []),
    [dataFields],
  );

  const [opacity, setOpacity] = useState(getOpacities(1));

  const [hiddenGroup, setHiddenGroup] = useState<GroupKeys>();
  const [focusDataKey, setFocusDataKey] = useState<string>();

  const handleMouseEnter = elem => {
    if (!isNilOrEmpty(focusDataKey)) {
      return;
    }
    const { dataKey } = elem;
    setOpacity({ ...getOpacities(0.25), [dataKey]: 1 });
  };

  const handleMouseLeave = () => setOpacity(getOpacities(1));

  const handleLabelClick = (groupKey: GroupKeys) => {
    setHiddenGroup(hiddenGroup === groupKey ? null : groupKey);
    setFocusDataKey(null);
  };

  const handleLegendClick = elem => {
    const { dataKey } = elem;
    setHiddenGroup(null);
    setFocusDataKey(dataKey === focusDataKey ? null : dataKey);
  };

  const refreshData = () => {
    setHiddenGroup(null);
    setFocusDataKey(null);
  };

  return (
    <ExpendituresChartContext.Provider
      value={{
        hiddenGroup,
        focusDataKey,
        actualFields,
        remainingFields,
        handleLabelClick,
        refreshData,
      }}
    >
      <>
        {loading || isNilOrEmpty(budgetsRaw) ? (
          <div style={styles.container}>
            <LinearProgress style={styles.progress} />
          </div>
        ) : (
          <div style={styles.container}>
            <ResponsiveContainer>
              <ComposedChart
                data={chartData}
                margin={{ top: CHART_TOP_MARGIN }}
              >
                <CartesianGrid vertical={false} />
                <XAxis tickLine={false} axisLine={false} dataKey="name" />
                <YAxis
                  tickLine={false}
                  tickFormatter={tickVal => yAxisTickFormatter(tickVal, maxVal)}
                />
                <Tooltip
                  formatter={numberToLocaleString}
                  content={<ChartTooltip />}
                />
                <Legend
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={handleLegendClick}
                  formatter={renderLegendText}
                  content={<ChartLegend />}
                />
                <ReferenceLine y={0} stroke={colors.neutral.black} />
                {actualFields.map((fieldName, idx) => (
                  <Bar
                    dataKey={fieldName}
                    key={fieldName}
                    barSize={40}
                    fill={getChartColor(idx)}
                    fillOpacity={opacity[fieldName]}
                    hide={
                      hiddenGroup === GroupKeys.ACTUAL ||
                      (focusDataKey && focusDataKey !== fieldName)
                    }
                    animationDuration={BAR_ANIMATION_MS}
                  />
                ))}
                {remainingFields.map((fieldName, idx) => (
                  <Line
                    type="monotone"
                    dataKey={fieldName}
                    key={fieldName}
                    stroke={getChartColor(idx)}
                    strokeWidth={2}
                    strokeOpacity={opacity[fieldName]}
                    hide={
                      hiddenGroup === GroupKeys.REMAINING ||
                      (focusDataKey && focusDataKey !== fieldName)
                    }
                    animationDuration={LINE_ANIMATION_MS}
                  />
                ))}
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        )}
      </>
    </ExpendituresChartContext.Provider>
  );
};

export default ExpendituresChart;
