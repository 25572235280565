import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Link } from 'react-router-dom';
import naturalSort from 'natural-sort';
import * as R from 'ramda';

import { Icon, Menu, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import { Budget } from '@atom/types/budget';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailContext from './BudgetDetailContext';
import { useBudgetFilterStorage } from './useBudgetFilterStorage';

import '../../styles/detail-header.css';

const styles = {
  menuIcon: {
    margin: '0.75rem',
    color: colors.neutral.white,
  },
  optionsContainer: {
    maxHeight: '330px',
    overflow: 'auto',
  },
};

interface Props {
  options: Budget[];
  total: number;
  page: number;
  loading: boolean;
  handlePageScroll?: (nextPage: number) => void;
}

const BudgetDetailBudgetNav = ({
  options = [],
  total = 0,
  page = 1,
  loading = false,
  handlePageScroll,
}: Props) => {
  const scrollContainerRef = useRef<any>(null);
  const { budget } = useContext(BudgetDetailContext);
  const { saveFilterState } = useBudgetFilterStorage();

  const handleScroll = useCallback(() => {
    const elem = scrollContainerRef?.current;
    const isBottom =
      elem?.scrollTop + elem?.clientHeight + 0.5 >= elem?.scrollHeight;
    if (isBottom && options.length < total && !loading) {
      handlePageScroll(page + 1);
    }
  }, [scrollContainerRef.current, total, page, options, handlePageScroll]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef?.current?.addEventListener('scroll', handleScroll);
      return () =>
        scrollContainerRef?.current?.removeEventListener(
          'scroll',
          handleScroll,
        );
    }
    return () => {};
  }, [handleScroll]);

  const sortedOptions = useMemo(
    () => (isNilOrEmpty(options) ? [] : R.sort(naturalSort, options)),
    [options],
  );

  const getLinkStyle = option =>
    option?.id === budget?.id ? 'menu-item selected' : 'menu-item';

  return (
    <>
      <Menu
        id="budget_navigation"
        noIconButton
        icon={<Icon style={styles.menuIcon}>arrow_drop_down</Icon>}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <div styleName="menu-heading">{budget.templateName}</div>
        <div ref={scrollContainerRef} style={styles.optionsContainer}>
          {sortedOptions?.map(option => (
            <Link
              to={`/budget/${option.id}`}
              styleName={getLinkStyle(option)}
              key={option.id}
              onClick={() => saveFilterState()}
            >
              {option.name}
            </Link>
          ))}
        </div>
        {loading && <Progress size={20} />}
      </Menu>
    </>
  );
};

export default BudgetDetailBudgetNav;
