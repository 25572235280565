// Returns access for budget states

import { useEffect, useState } from 'react';

import { PolicyAction } from '@atom/types/policy';
import { hasAccess } from '@atom/utilities/accessUtilities';

export const useBudgetApprovalAccess = (actions: PolicyAction[]) => {
  const [canAccessApprove, setCanAccessApprove] = useState<boolean>(false);
  const [canAccessSubmit, setCanAccessSubmit] = useState<boolean>(false);
  const [
    canAccessRevertToDraftFromApproved,
    setCanAccessRevertToDraftFromApproved,
  ] = useState<boolean>(false);
  const [
    canAccessRevertToDraftFromSubmitted,
    setCanAccessRevertToDraftFromSubmitted,
  ] = useState<boolean>(false);
  const [
    canAccessRevertToSubmittedFromApproved,
    setCanAccessRevertToSubmittedFromApproved,
  ] = useState<boolean>(false);

  useEffect(() => {
    setCanAccessApprove(hasAccess(PolicyAction.APPROVE, actions));
    setCanAccessSubmit(hasAccess(PolicyAction.SUBMIT_DRAFT, actions));
    setCanAccessRevertToDraftFromApproved(
      hasAccess(PolicyAction.REVERT_TO_DRAFT_FROM_APPROVED, actions),
    );
    setCanAccessRevertToDraftFromSubmitted(
      hasAccess(PolicyAction.REVERT_TO_DRAFT_FROM_SUBMITTED, actions),
    );
    setCanAccessRevertToSubmittedFromApproved(
      hasAccess(PolicyAction.REVERT_TO_SUBMITTED_FROM_APPROVED, actions),
    );
  }, [actions]);

  return {
    canAccessApprove,
    canAccessSubmit,
    canAccessRevertToDraftFromApproved,
    canAccessRevertToDraftFromSubmitted,
    canAccessRevertToSubmittedFromApproved,
  };
};
