import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
} from 'react';
import * as R from 'ramda';

import PercentBar from '@atom/components/common/percentBar/PercentBar';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Icon, List, ListTable, Skeleton } from '@atom/mui';
import colors from '@atom/styles/colors';
import { BasicBudget, BudgetSummary } from '@atom/types/budget';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailContext from './BudgetDetailContext';
import {
  BUDGET_COL_WIDTH,
  COMPARE_MENU_HEIGHT,
  EmptyComparisonChart,
  getDateRange,
  getStatusLabel,
} from './budgetDetailUtils';

import './budgetDetail.css';

const { TableRow, TableCell, TableBody } = ListTable;
const { ListItemText } = List;

const styles = {
  comparisonContainer: {
    maxHeight: COMPARE_MENU_HEIGHT,
    minWidth: '30rem',
    padding: '3rem 0 0',
    position: 'relative',
    overflow: 'auto',
  },
  loadingContainer: {
    width: '100%',
  },
  comparisonPrimaryText: {
    color: colors.neutral.black,
    fontWeight: 400,
  },
  textNoWrap: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '17rem',
    paddingRight: '1.5rem',
  },
  statusLabel: {
    paddingRight: '0.5rem',
  },
};

interface Props {
  setOpen: Dispatch<SetStateAction<boolean>>;
  loadingSummaries: boolean;
  budgetOverviewSummaries: BudgetSummary[];
}

const BudgetDetailOverviewComparison = ({
  setOpen,
  loadingSummaries,
  budgetOverviewSummaries,
}: Props) => {
  const { comparisonBudgets, showTracking } = useContext(BudgetDetailContext);

  const preferences = usePreferences();
  const showApprovalFlow: boolean = R.pathOr(
    false,
    ['budgeting', 'showApprovalFlow'],
    preferences,
  );

  const getTitleNode = (budget: BasicBudget) => (
    <ListItemText
      primary={budget.name}
      primaryTextStyle={{
        ...styles.comparisonPrimaryText,
        ...styles.textNoWrap,
      }}
      primaryTypographyProps={{ style: { whiteSpace: 'nowrap' } }}
      secondary={getDateRange(budget)}
      secondaryTextStyle={styles.textNoWrap}
    />
  );

  const getSummary = useCallback(
    (id: string): BudgetSummary =>
      budgetOverviewSummaries.find(summary => summary.id === id),
    [budgetOverviewSummaries],
  );

  const getCurrencyField = (id: string, field: keyof BudgetSummary) =>
    numberToLocaleString(getSummary(id)?.[field]);

  const getDifferenceNode = (id: string) => (
    <PercentBar
      value={getSummary(id)?.remaining}
      limit={getSummary(id)?.budgetValue}
    />
  );

  const HeadingCell = content => (
    <TableCell width={BUDGET_COL_WIDTH} align="right">
      {content}
    </TableCell>
  );

  const ValueCell = content => (
    <TableCell width={BUDGET_COL_WIDTH} align="right">
      {loadingSummaries ? <Skeleton /> : content}
    </TableCell>
  );

  return (
    <div style={styles.comparisonContainer}>
      <div styleName="comparison-menu-heading">
        Compare with Reference Budgets
        <Icon styleName="clickable" onClick={() => setOpen(false)}>
          close
        </Icon>
      </div>
      {isNilOrEmpty(comparisonBudgets) ? (
        EmptyComparisonChart
      ) : (
        <ListTable fullHeight={false}>
          <TableBody>
            <TableRow>
              <TableCell />
              {HeadingCell('Total Budget')}
              {showTracking && (
                <>
                  {HeadingCell('Total Actual')}
                  {HeadingCell('Total Future Fixed')}
                  {HeadingCell('Total Remaining')}
                </>
              )}
            </TableRow>
            {comparisonBudgets.map(budget => (
              <TableRow key={budget.id} style={styles.loadingContainer}>
                <TableCell>
                  {showApprovalFlow && (
                    <span style={styles.statusLabel}>
                      {getStatusLabel(budget?.status, true)}
                    </span>
                  )}
                  {getTitleNode(budget)}
                </TableCell>
                {ValueCell(getCurrencyField(budget.id, 'budgetValue'))}
                {showTracking && (
                  <>
                    {ValueCell(getCurrencyField(budget.id, 'actualBudget'))}
                    {ValueCell(getCurrencyField(budget.id, 'fixedCosts'))}
                    {ValueCell(getDifferenceNode(budget.id))}
                  </>
                )}
                <TableCell />
              </TableRow>
            ))}
          </TableBody>
        </ListTable>
      )}
    </div>
  );
};

export default BudgetDetailOverviewComparison;
