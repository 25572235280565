import React, { useContext, useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';

import InventoryAssetTree from '@atom/components/common/inventoryAssetTree/InventoryAssetTree';
import WorkOrdersContext, {
  WorkOrdersInputActionTypes,
} from '@atom/components/workOrders/WorkOrdersContext';
import { useInventoryCategoryTree } from '@atom/hooks/useInventoryCategoryTree';
import { Button, Modal, Progress } from '@atom/mui';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import {
  getAllDescendantCategoryIds,
  getCategoriesByIds,
} from '@atom/utilities/categoryUtilities';
// TODO: [AM-15829]: Release cost based as work root feature
import {
  Environment,
  isCurrentEnvironment,
} from '@atom/utilities/featureToggleUtilities';

interface Category {
  id: string;
  name: string;
  categories?: Category[];
}

const styles = {
  modal: {
    height: '50vh',
    padding: '2rem',
  },
  label: {
    fontSize: fonts.sm,
    color: colors.neutral.dim,
    marginBottom: '0.25rem',
  },
  value: {
    fontSize: fonts.md,
    color: colors.neutral.dark,
    marginBottom: '0.5rem',
  },
  button: {
    minWidth: 0,
    padding: 0,
  },
};

const WorkOrdersCategoryFilter = () => {
  const { workOrdersInputCart, filtersDisabled, dispatch } = useContext(
    WorkOrdersContext,
  );

  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<Category[]>([]);
  const [applied, setApplied] = useState<Category[]>([]);

  const [categoryTree, { loading, refetch }] = useInventoryCategoryTree({
    lazy: true,
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (categoryTree) {
      const categories = getCategoriesByIds(workOrdersInputCart.categoryIds, [
        categoryTree,
      ]);

      setSelected(categories);
      setApplied(categories);
    }
  }, [categoryTree]);

  useEffect(() => {
    const categories = selected.filter(({ id }) =>
      workOrdersInputCart.categoryIds.includes(id),
    );

    setSelected(categories);
    setApplied(categories);
  }, [workOrdersInputCart.categoryIds]);

  const handleToggle = (category: Category) => {
    const isSelected = selected.some(({ id }) => category.id === id);

    setSelected(
      isSelected
        ? selected.filter(({ id }) => id !== category.id)
        : [...selected, category],
    );
  };

  const handleSave = () => {
    const descendantIds = new Set(
      selected.reduce((acc, category): string[] => {
        return [...acc, ...getAllDescendantCategoryIds(category)];
      }, []),
    );

    // remove all categories that are descendants of another selected category
    // descendant categories are auto-selected and don't need to be shown or
    // included in the API request
    const categoryIds = selected
      .map(({ id }) => id)
      .filter(id => !descendantIds.has(id));

    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_WORK_ORDERS_INPUT_PROPERTY,
      data: {
        property: 'categoryIds',
        value: categoryIds,
      },
    });

    setApplied(selected);
    setOpen(false);
  };

  const handleReset = () => {
    dispatch({
      type: WorkOrdersInputActionTypes.UPDATE_WORK_ORDERS_INPUT_PROPERTY,
      data: {
        property: 'categoryIds',
        value: [],
      },
    });
  };

  const handleCancel = () => {
    setSelected(applied);
    setOpen(false);
  };

  const selectedIds = useMemo(() => {
    return new Set(selected.map(({ id }) => id));
  }, [selected]);

  return (
    <>
      <div style={styles.label}>Inventory Folders</div>
      <div style={styles.value}>
        {loading && (
          <Progress size={20} style={{ justifyContent: 'flex-start' }} />
        )}
        {applied.map(({ name }) => name).join(', ')}
      </div>
      {R.length(workOrdersInputCart.categoryIds) > 0 && (
        <Button
          style={{ ...styles.button, marginRight: '1rem' }}
          size="small"
          disabled={filtersDisabled || loading}
          onClick={handleReset}
        >
          clear
        </Button>
      )}
      <Button
        style={styles.button}
        size="small"
        onClick={() => setOpen(true)}
        disabled={filtersDisabled || loading}
      >
        select
      </Button>
      <Modal
        title="Add Inventory Folders"
        open={open}
        onCancel={handleCancel}
        onConfirm={handleSave}
        contentStyle={styles.modal}
      >
        <InventoryAssetTree
          includeAssets={false}
          selectedCategoryIds={selectedIds}
          {...(isCurrentEnvironment([Environment.DEV, Environment.QA])
            ? {}
            : { includeMaterials: false })}
          onCategoryToggle={handleToggle}
          search="categories"
          autoSelectChildCategories
          showRootCategoryCheckbox
        />
      </Modal>
    </>
  );
};

export default WorkOrdersCategoryFilter;
