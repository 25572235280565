import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import InventoryAssetTile from '@atom/components/common/inventoryAssetTree/InventoryAssetTile';
import InventoryAssetTree from '@atom/components/common/inventoryAssetTree/InventoryAssetTree';
// @ts-ignore
import renameIcon from '@atom/components/common/svgIcons/renameIcon.svg';
import { WORK_ORDER_UPDATE } from '@atom/graph/work';
import { usePreferences } from '@atom/hooks/usePreferences';
import { useSchemas } from '@atom/hooks/useSchemas';
import { useUserProfile } from '@atom/hooks/useUserProfile';
import {
  DatePicker,
  Icon,
  IconButton,
  Modal,
  Switch,
  TextField,
} from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  WorkOrderDetailType,
  WorkOrderType,
  WorkOrderUpdate,
  WorkOrderUpdateInput,
} from '@atom/types/work';
import {
  doesNotHaveRolePermissions,
  ROLE_SETS,
} from '@atom/utilities/authUtilities';
// TODO: [AM-15829]: Release cost based as work root feature
import {
  Environment,
  isCurrentEnvironment,
} from '@atom/utilities/featureToggleUtilities';
import { convertDateToMillisGMTMidday } from '@atom/utilities/timeUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import './workOrderDetailEdit.css';

enum View {
  MAIN = 'MAIN',
  ASSET_TREE = 'ASSET_TREE',
  ASSET_CHANGE_CONFIRMATION = 'ASSET_CHANGE_CONFIRMATION',
}

interface RootAsset {
  id: string;
  name: string;
  schemaId: string;
  markerId: number;
  isMaterial?: boolean;
}

const styles = {
  icon: {
    marginRight: '2rem',
  },
  back: {
    marginRight: '1rem',
  },
  modalContent: {
    padding: '2rem',
  },
};

const modalContentStyles: { [key in View]: React.CSSProperties } = {
  [View.MAIN]: {
    ...styles.modalContent,
  },
  [View.ASSET_TREE]: {
    ...styles.modalContent,
    height: '50vh',
  },
  [View.ASSET_CHANGE_CONFIRMATION]: {
    ...styles.modalContent,
  },
};

const modalConfirmTexts: { [key in View]: string } = {
  [View.MAIN]: 'Save',
  [View.ASSET_TREE]: 'Save',
  [View.ASSET_CHANGE_CONFIRMATION]: 'Confirm',
};

interface Props {
  workOrderDetail: WorkOrderDetailType;
  refetch: () => void;
  iconColor?: string;
}

const WorkOrderDetailEdit = ({
  workOrderDetail,
  refetch,
  iconColor = colors.neutral.gray,
}: Props) => {
  const userProfile = useUserProfile();
  const preferences = usePreferences();
  const { schemas = [] } = useSchemas();

  const [open, setOpen] = useState<boolean>(false);
  const [view, setView] = useState<View>(View.MAIN);
  const [name, setName] = useState<string>('');
  const [dueDate, setDueDate] = useState<Date>(null);
  const [completionDate, setCompletionDate] = useState<Date>(null);
  const [rootAsset, setRootAsset] = useState<RootAsset>(null);
  const [isLocationBased, setIsLocationBased] = useState<boolean>(
    workOrderDetail.isLocationBased,
  );

  const isRootAssetAdded = !workOrderDetail.inventoryAssetId && !!rootAsset;
  const isRootAssetRemoved = workOrderDetail.inventoryAssetId && !rootAsset;
  const isRootAssetUpdated =
    workOrderDetail.inventoryAssetId &&
    rootAsset &&
    rootAsset.id !== workOrderDetail.inventoryAssetId;
  const isRootAssetChanged =
    isRootAssetAdded || isRootAssetRemoved || isRootAssetUpdated;

  const isWorkNamingDisabled = preferences.workOrders?.workNamingDisabled;

  const workType = R.pathOr(WorkOrderType.DEFAULT, ['type'], workOrderDetail);

  const [updateWorkDetail, { loading }] = useMutation<
    { workOrderUpdate: WorkOrderUpdate },
    { workOrder: WorkOrderUpdateInput }
  >(WORK_ORDER_UPDATE, {
    onCompleted: () => {
      setOpen(false);
      refetch();
    },
  });

  const rootSchema = useMemo(() => {
    return R.find(
      R.propEq('id', workOrderDetail.inventoryAssetSchemaId),
      schemas,
    );
  }, [workOrderDetail, schemas]);

  useEffect(() => {
    setRootAsset(prev => ({ ...prev, isMaterial: rootSchema?.isMaterial }));
  }, [rootSchema]);

  const resetState = () => {
    setView(View.MAIN);
    setName(workOrderDetail.originalName);
    setIsLocationBased(workOrderDetail.isLocationBased);
    setDueDate(
      workOrderDetail.dueDate ? new Date(workOrderDetail.dueDate) : null,
    );
    setCompletionDate(
      workOrderDetail.completionDate
        ? new Date(workOrderDetail.completionDate)
        : null,
    );

    if (workOrderDetail.inventoryAssetId) {
      setRootAsset({
        id: workOrderDetail.inventoryAssetId,
        schemaId: workOrderDetail.inventoryAssetSchemaId,
        name: workOrderDetail.inventoryAssetName,
        markerId: workOrderDetail.inventoryAssetMarkerId,
        isMaterial: rootSchema?.isMaterial,
      });
    } else {
      setRootAsset(null);
    }
  };

  useEffect(() => {
    resetState();
  }, [workOrderDetail]);

  const updateWorkOrder = () => {
    return updateWorkDetail({
      variables: {
        workOrder: {
          id: workOrderDetail.id,
          name,
          ...R.reject(R.isNil, {
            dueDate: dueDate ? dueDate.valueOf() : '',
            completionDate: completionDate
              ? convertDateToMillisGMTMidday(completionDate)
              : null,
          }),
          ...(isLocationBased !== workOrderDetail.isLocationBased && {
            isLocationBased,
          }),
          ...(isLocationBased &&
            !workOrderDetail.location && {
              location: userProfile.location,
            }),
          ...(isRootAssetChanged && {
            inventoryAssetId: rootAsset?.id || '',
          }),
        },
      },
    });
  };

  const handleSubmit = () => {
    if (!name) {
      return null;
    }

    if (view === View.MAIN && (isRootAssetUpdated || isRootAssetRemoved)) {
      return setView(View.ASSET_CHANGE_CONFIRMATION);
    }

    return updateWorkOrder();
  };

  const handleCompletionDateChange = (date?: Date) => {
    if (!date) {
      setCompletionDate(null);
    } else if (!Number.isNaN(date.valueOf())) {
      setCompletionDate(date);
    }
  };

  const handleDueDateChange = (date?: Date) => {
    if (!date) {
      setDueDate(null);
    } else if (!Number.isNaN(date.valueOf())) {
      setDueDate(date);
    }
  };

  const handleRootAssetChange = (asset: RootAsset) => {
    setIsLocationBased(!asset?.isMaterial);
    setRootAsset(asset);
    setView(View.MAIN);
  };

  const handleRootAssetRemove = () => {
    setRootAsset(null);
    setIsLocationBased(false);
  };

  const modalHeaders: { [key in View]: React.ReactNode } = useMemo(() => {
    return {
      [View.MAIN]: 'Edit Work',
      [View.ASSET_TREE]: (
        <>
          <IconButton style={styles.back} onClick={() => setView(View.MAIN)}>
            <Icon>arrow_back</Icon>
          </IconButton>
          Edit Asset
        </>
      ),
      [View.ASSET_CHANGE_CONFIRMATION]: 'Remove Asset?',
    };
  }, [setView]);

  const isSaveDisabled = !name;

  const showAssetAndLocation =
    workType === WorkOrderType.DEFAULT ||
    isNilOrEmpty(workOrderDetail?.workTemplate);

  const getContent = () => {
    const content = {
      [View.MAIN]: (
        <div>
          <div styleName="modal-row name">
            <Icon style={styles.icon}>
              <img src={renameIcon} />
            </Icon>
            <TextField
              id="workOrderName"
              label="Work Name"
              value={name}
              onChange={event => setName(event.target.value)}
              disabled={!!isWorkNamingDisabled}
              required
            />
          </div>
          <div styleName="modal-row due-date">
            <Icon style={styles.icon}>event</Icon>
            <DatePicker
              label="Due Date"
              value={dueDate}
              onChange={handleDueDateChange}
            />
          </div>
          <div styleName="modal-row no-icon">
            <DatePicker
              value={completionDate}
              onChange={handleCompletionDateChange}
              label="Completed Date"
              style={{ marginBottom: '1rem' }}
            />
          </div>
          {showAssetAndLocation && (
            <>
              <div styleName="modal-row asset">
                <Icon style={styles.icon}>category</Icon>
                <InventoryAssetTile
                  asset={rootAsset}
                  onClick={() => setView(View.ASSET_TREE)}
                  onRemove={handleRootAssetRemove}
                />
              </div>
              <div styleName="toggle-container">
                <Switch
                  checked={isLocationBased}
                  onChange={() => setIsLocationBased(!isLocationBased)}
                  name="isLocationBased"
                  disabled={
                    (!R.isEmpty(workOrderDetail.inventoryAssetId) &&
                      !!rootAsset) ||
                    !!rootAsset
                  }
                  label="Location"
                />
              </div>
            </>
          )}
        </div>
      ),
      [View.ASSET_TREE]: (
        <InventoryAssetTree
          onAssetClick={handleRootAssetChange}
          {...(isCurrentEnvironment([Environment.DEV, Environment.QA])
            ? {}
            : { includeMaterials: false })}
          search="assets"
          // if the work template for this work has a schema ID,
          // we only allow the user to select assets that have that schema.
          {...(workOrderDetail.workTemplate?.schemaId && {
            schemaId: workOrderDetail.workTemplate?.schemaId,
          })}
        />
      ),
      [View.ASSET_CHANGE_CONFIRMATION]: (
        <div>
          Removing the asset will remove assets and forms of the same asset type
          from all tasks. Are you sure?
        </div>
      ),
    };

    return content[view];
  };

  return (
    <div styleName="icon-modal-container">
      <IconButton
        edge="end"
        onClick={() => setOpen(true)}
        tooltip="Edit"
        disabled={doesNotHaveRolePermissions(ROLE_SETS.INSPECTOR)}
      >
        <Icon color={iconColor}>edit</Icon>
      </IconButton>
      <Modal
        title={modalHeaders[view] || 'Edit Work'}
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={handleSubmit}
        confirmButtonText={modalConfirmTexts[view] || 'Save'}
        loading={loading || isSaveDisabled}
        onExited={resetState}
        contentStyle={modalContentStyles[view] || styles.modalContent}
      >
        {getContent()}
      </Modal>
    </div>
  );
};

export default WorkOrderDetailEdit;
