import React, { useContext, useMemo } from 'react';

import WorkTemplateContext from '@atom/components/workTemplate/WorkTemplateContext';
import { useSchemas } from '@atom/hooks/useSchemas';
import { Icon, Select } from '@atom/mui';
import colors from '@atom/styles/colors';
import { InventorySchemaItem } from '@atom/types/inventory';
import { InheritedComponentType } from '@atom/types/workTemplate';
// TODO: [AM-15829]: Release cost based as work root feature
import {
  Environment,
  isCurrentEnvironment,
} from '@atom/utilities/featureToggleUtilities';
import schemaUtilities from '@atom/utilities/schemaUtilities';
import {
  isComponentInherited,
  rollUpTaskInheritedComponent,
  WorkTypeVariant,
} from '@atom/utilities/workTemplateUtilities';

import './workTypeEditModal.css';

const { MenuItem } = Select;

const EMPTY_SCHEMA = 'empty';

const styles = {
  textFieldStyle: {
    marginLeft: '1rem',
    color: colors.neutral.dark,
  },
  icon: {
    marginTop: '1rem',
  },
};

interface Props {
  schemaId: string;
  setSchemaId: (schemaId: string) => void;
}

const WorkTemplateSchemaSelect = ({ schemaId, setSchemaId }: Props) => {
  const { workTemplate, workTypeVariant } = useContext(WorkTemplateContext);

  const { schemas = [] } = useSchemas();

  const locationBasedSchemas = useMemo(() => {
    return schemas.filter(schema => !schema.isMaterial);
  }, [schemas]);

  const schemasData = isCurrentEnvironment([Environment.DEV, Environment.QA])
    ? schemas
    : locationBasedSchemas;

  const isSchemaInherited = isComponentInherited(
    InheritedComponentType.TASK_SCHEMA,
    rollUpTaskInheritedComponent(
      workTemplate,
      InheritedComponentType.TASK_SCHEMA,
    ),
    workTemplate?.schemaId,
  );

  const isSchemaDisabled =
    workTemplate.published ||
    (workTypeVariant === WorkTypeVariant.CHILD && isSchemaInherited);

  const schemaHelperText =
    workTypeVariant === WorkTypeVariant.CHILD && isSchemaInherited
      ? 'Inherited from the parent work template'
      : '';

  return (
    <div styleName="modal-row">
      <Icon color={colors.neutral.gray} style={styles.icon}>
        category
      </Icon>
      <Select
        value={schemaId}
        style={styles.textFieldStyle}
        onChange={event => setSchemaId(String(event.target.value))}
        label="Asset Type"
        fullWidth
        disabled={isSchemaDisabled}
        helperText={schemaHelperText}
      >
        <MenuItem key="empty" value={EMPTY_SCHEMA}>
          <div styleName="schema-menu-item-container">
            <div styleName="empty-icon">-</div>
            <div styleName="schema-menu-item-title empty">All Asset Types</div>
          </div>
        </MenuItem>
        {schemasData.map((schema: InventorySchemaItem) => {
          const icon = schemaUtilities.getSchemaIconFromSchemaOrAsset(schema);

          return (
            <MenuItem key={schema.id} value={schema.id}>
              <div styleName="schema-menu-item-container">
                {icon}
                <div styleName="schema-menu-item-title">{schema.name}</div>
              </div>
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export default WorkTemplateSchemaSelect;
