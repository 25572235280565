import React from 'react';

import { Icon } from '@atom/mui';
import markerUtilities from '@atom/utilities/markerUtilities';

const NO_ASSET_TYPE_LEAF_NODE_COLOR = '#999999';

const DEFAULT_ASSET_SCHEMA_COLOR_ID = 0;

const DEFAULT_ASSET_SCHEMA_MARKER_ID = 8;

const colorSwatch = [
  '#00BCD4', // 0
  '#509DE9', // 1
  '#4EBFD8', // 2
  '#69D7C8', // 3
  '#999CFF', // 4
  '#B99AFF', // 5
  '#F690B2', // 6
  '#9209AE', // 7
  '#7F57C2', // 8
  '#4A18BD', // 9
  '#1D905B', // 10
  '#31CA82', // 11
  '#7BD148', // 12
  '#B3DD6B', // 13
  '#DBDF5F', // 14
  '#ECCE31', // 15
  '#417505', // 16
  '#146495', // 17
  '#0048C7', // 18
  '#555555', // 19
  '#AC715D', // 20
  '#D06B63', // 21
  '#F93922', // 22
  '#FA573B', // 23
  '#FF7537', // 24
  '#FBC02D', // 25
  '#BE681C', // 26
  '#8B572A', // 27
  '#513838', // 28
  '#333333', // 29
  '#00BCD4', // 30
  '#00A89A', // 31
  '#21A567', // 32
  '#417505', // 33
  '#FF8F00', // 34
  '#FFA800', // 35
  '#E354B4', // 36
  '#7F57C2', // 37
  '#0048C7', // 38
  '#555555', // 39
  '#509DE9', // 40
  '#1BBCD7', // 41
  '#00A89A', // 42
  '#1D905B', // 43
  '#BE681C', // 44
  '#6B4927', // 45
  '#513838', // 46
  '#555555', // 47
  '#555555', // 48
  '#333333', // 49
];

const getSchemaColorFromColorId = schemaColorId => {
  if (schemaColorId === 'DEFAULT') {
    return NO_ASSET_TYPE_LEAF_NODE_COLOR;
  }

  return (
    colorSwatch[schemaColorId] || colorSwatch[DEFAULT_ASSET_SCHEMA_COLOR_ID]
  );
};

const schemaIconsList = markerUtilities.assetMarkers;

export const getSchemaIconFromMarkerId = markerId => {
  return (
    schemaIconsList[markerId] || schemaIconsList[DEFAULT_ASSET_SCHEMA_MARKER_ID]
  );
};

export const getSchemaIconFromSchemaOrAsset = (
  schema,
  params = {
    imgOffset: '0',
    iconSize: '34px',
    marginTop: '3px',
  },
) => {
  if (!schema) {
    return <div />;
  }

  const { imgOffset, iconSize, marginTop } = params;
  const { isMaterial, colorId, markerId } = schema;
  const svgIcon =
    schemaIconsList[markerId] ||
    schemaIconsList[DEFAULT_ASSET_SCHEMA_MARKER_ID];

  const schemaColor = colorSwatch[colorId] || 0;

  const iconStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: schemaColor,
    width: iconSize,
    height: iconSize,
  };

  const imgStyle = {
    marginTop,
    marginLeft: imgOffset,
    marginRight: imgOffset,
  };

  return isMaterial ? (
    <Icon style={iconStyle}>category</Icon>
  ) : (
    <img style={imgStyle} src={svgIcon} />
  );
};

const schemaUtilities = {
  DEFAULT_ASSET_SCHEMA_COLOR_ID,
  getSchemaColorFromColorId,
  getSchemaIconFromMarkerId,
  getSchemaIconFromSchemaOrAsset,
  colorSwatch,
  schemaIconsList,
};

export default schemaUtilities;
